<template>
  <portal to="export-info-modal">
    <div class="export-info-modal" @click="closeModal"></div>
    <div v-if="exportInfoMode === 'warning'" class="export-info-wrap">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />{{
          $t('export_warning_title')
        }}
      </div>
      <div class="content">
        <p>{{ $t('export_warning_content') }}</p>
        <div class="btn" @click="closeModal">{{ $t('confirm') }}</div>
      </div>
    </div>
    <div v-else class="custom-filename-wrap">
      <div class="title">{{ $t('export_custom_filename') }}</div>
      <input v-model="exportFilename" type="text" />
      <!-- <div class="sub-title">{{ $t('export_search_criteria') }}</div>
      <div class="query">
        <div
          v-for="(value, key) in getEventsParams"
          :key="key"
          class="query-item"
        >
          <span class="key">{{ key }}：</span>{{ value }}
        </div>
      </div> -->
      <div class="btns">
        <div class="btn cancel" @click="cancelExport">{{ $t('cancel') }}</div>
        <div class="btn" @click="handleExport">{{ $t('confirm') }}</div>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ExportInfoModal',
  data() {
    return {
      defaultFilename: '',
      exportFilename: '',
    }
  },
  computed: {
    ...mapState('history', ['searchMode', 'exportInfoMode', 'getEventsParams', 'exportList']),
    ...mapGetters(['getUserInfo', 'timezone']),
  },
  mounted() {
    const nowTime = moment().tz(this.timezone).format('YYYYMMDDHHmmss')
    if (this.searchMode === 'basic') {
      // 一般查詢： <搜查車號>_<開始日期>-<結束日期>_<查詢者姓名(帳號)>_<查詢時間>.zip
      // 例：ABC-1234_20220130-20220430_王大明(typd001)_20220816182755.zip
      const startDate = moment(this.getEventsParams.afterDetectTime).tz(this.timezone).format('YYYYMMDD')
      const endDate = moment(this.getEventsParams.beforeDetectTime).tz(this.timezone).format('YYYYMMDD')
      this.defaultFilename = `${this.getEventsParams.content}_${startDate}-${endDate}_${this.getUserInfo.name}(${this.getUserInfo.id})_${nowTime}`
    } else {
      // 進階查詢：進階查詢_<查詢人姓名(帳號)>_<查詢時間>.zip
      // 例：進階查詢_王大明(typd001)_20220816182755.zip
      this.defaultFilename = `進階查詢_${this.getUserInfo.name}(${this.getUserInfo.id})_${nowTime}`
    }
    this.exportFilename = this.defaultFilename
  },
  methods: {
    ...mapMutations('history', ['updateShowExportInfoModal']),
    ...mapActions('history', ['exportBasicReport', 'exportAdvancedReport']),
    closeModal() {
      this.updateShowExportInfoModal(false)
    },
    cancelExport() {
      this.closeModal()
    },
    handleExport() {
      if (this.exportFilename === '') this.exportFilename = this.defaultFilename
      if (this.searchMode === 'basic')
        this.exportBasicReport(this.exportFilename) // 一般查詢的匯出
      else if (this.searchMode === 'advanced')
        this.exportAdvancedReport(this.exportFilename) // 進階查詢的匯出
      this.closeModal()
    },
  }
}
</script>

<style lang="scss" scoped>
.export-info-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 5;
}

.export-info-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 780px;
  z-index: 5;
}

.header {
  background: #4a5c78;
  color: #ffffff;
  border-radius: 23px 23px 0px 0px;
  font-size: 23px;
  font-weight: 300;
  padding: 15px 32px;
  display: flex;
  align-items: center;
}

.header img {
  width: 24px;
  height: 24px;
  margin-right: 22px;
}

.content {
  background: #282942;
  border-radius: 0px 0px 23px 23px;
  text-align: center;
  position: relative;
  top: -32px;
}

.content > p {
  font-size: px2rem(28);
  line-height: 44px;
  font-weight: 300;
  color: #ffffff;
  padding-top: 40px;
  padding-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  font-size: px2rem(20);
  font-weight: 300;
  color: #282942;
  padding: 10px 48px;
  margin-bottom: 50px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
}

/* 自訂檔名 */
.custom-filename-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  padding: 32px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  color: #ffffff;
  background: #282942;
  border: 1px solid #4a5c78;
  z-index: 5;
}

.custom-filename-wrap .title {
  font-size: px2rem(28);
  line-height: 54px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.custom-filename-wrap input {
  width: 100%;
  height: 48px;
  outline: none;
  border: 1px solid #9d9d9d;
  border-radius: 8px;
  font-size: px2rem(20);
  font-weight: 300;
  line-height: 36px;
  padding: 6px 12px;
  margin-bottom: 48px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-filename-wrap .sub-title {
  font-size: px2rem(20);
  line-height: 36px;
  position: relative;
  margin-bottom: 8px;
}

.custom-filename-wrap .sub-title::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ffffff80;
  left: 0;
  top: -24px;
}

.custom-filename-wrap .query {
  margin-bottom: 24px;
}

.custom-filename-wrap .query-item {
  font-size: px2rem(20);
  line-height: 35px;
  margin-bottom: 8px;
}

.custom-filename-wrap .query-item .key {
  color: #ffffff80;
}

.custom-filename-wrap .btns {
  display: flex;
  justify-content: center;
}

.btns .btn {
  margin-bottom: 0px;
}

.btns .btn.cancel {
  border: #ffc600 1px solid;
  background: #282942;
  color: #ffc600;
}

.btns .btn.cancel:hover {
  background: #ffc60020;
}

.btns .btn:not(:last-child) {
  margin-right: 32px;
}
</style>